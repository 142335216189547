import { atom, Getter, Setter } from "jotai";
import { atomsWithQuery } from "jotai-tanstack-query";
import { atomWithStoredHash } from "../utils/state";

import { DefaultService, Parameters } from "../client";
import { Parameters as Parameters_V1 } from "../oldModels/V1/";

import { getQueryClient } from "../utils/query-client";
import { updateParamsToLatestVersion } from "../utils/updaters/updaters";

export const [defaultParamsAtom] = atomsWithQuery(
  (_get: Getter) => ({
    queryKey: ["defaultParams"],
    queryFn: async () => {
      return await DefaultService.getDefaultParams();
    },
  }),
  getQueryClient,
);

defaultParamsAtom.debugLabel = "defaultParams";

export const paramsAtomStored = atomWithStoredHash<Parameters | Parameters_V1>(
  "params",
  {},
);

export const paramsAtom = atom<Parameters, [Parameters], void>(
  (get: Getter) => updateParamsToLatestVersion(get(paramsAtomStored)),
  (_get: Getter, set: Setter, newValue: Parameters) =>
    set(paramsAtomStored, newValue),
);

export const [paramAttributesAtom] = atomsWithQuery(
  (_get) => ({
    queryKey: ["paramAttributes"],
    queryFn: async () => {
      return await DefaultService.getParameterAttributes();
    },
  }),
  getQueryClient,
);

paramAttributesAtom.debugLabel = "paramAttributes";
