import { ConfigurableDistribution } from "../../components/configurableDistribution/ConfigurableDistribution";
import { FormatType } from "../../utils/formatting";
import { isEqual } from "lodash-es";
import { useAtomValue } from "jotai";
import { paramAttributesAtom } from "../../stores/params";
import { DistributionSpec } from "../../utils/distributions";

export function ConfigurableDistributionParam({
  customDistribution,
  baseDistribution,
  setDistribution,
  name,
  type = "decimal",
  unit,
}: {
  customDistribution: DistributionSpec | undefined;
  baseDistribution: DistributionSpec;
  setDistribution: (dist: DistributionSpec | undefined) => void;
  name: string;
  type?: FormatType;
  unit?: string;
}) {
  const isModified =
    customDistribution !== undefined &&
    !isEqual(customDistribution, baseDistribution);
  const paramAttributes = useAtomValue(paramAttributesAtom);

  const metadata = paramAttributes[name];

  // Create key out of custom params to force re-render when they change.
  const key = Object.values(customDistribution ?? {}).join(",");

  return ConfigurableDistribution({
    metadata: metadata,
    customDistribution: customDistribution,
    baseDistribution: baseDistribution,
    setDistribution: setDistribution,
    isModified: isModified,
    key: key,
    type: type,
    unit: unit,
  });
}
