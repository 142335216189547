import { atom, Getter, Setter } from "jotai";
// import { atomWithLocation } from "jotai-location";
import { atomsWithQuery } from "jotai-tanstack-query";

import {
  AnimalIntervention,
  DefaultService,
  GhdIntervention,
  Parameters,
  XRiskIntervention,
} from "../client";
import { AnimalIntervention as AnimalIntervention_V1 } from "../oldModels/V1/";
import { Animal } from "../client/models/Animal";

import { paramsAtom } from "./params";
import { updateAnimalInterventionToLatestVersion } from "../utils/updaters/updaters";

import { getQueryClient } from "../utils/query-client";

// export interface AtomLocationType {
// pathname?: string | undefined;
// searchParams?: URLSearchParams | undefined;
// }

// export const locationAtom = atomWithLocation();

import { atomWithStoredHash } from "../utils/state";

export const baseGhdInterventionAtom = atomWithStoredHash<
  GhdIntervention | undefined
>("baseGhdIntervention", undefined);

export const customGhdInterventionAtom = atomWithStoredHash<
  GhdIntervention | undefined
>("customGhdIntervention", undefined);

const storedBaseAnimalInterventionAtom = atomWithStoredHash<
  AnimalIntervention | undefined
>("baseAnimalIntervention", undefined);

export const baseAnimalInterventionAtom = atom<
  AnimalIntervention | AnimalIntervention_V1 | undefined,
  [AnimalIntervention | undefined],
  void
>(
  (get: Getter) =>
    updateAnimalInterventionToLatestVersion(
      get(storedBaseAnimalInterventionAtom),
    ),
  (_get: Getter, set: Setter, newValue: AnimalIntervention | undefined) =>
    set(storedBaseAnimalInterventionAtom, newValue),
);

const storedCustomAnimalInterventionAtom = atomWithStoredHash<
  AnimalIntervention | AnimalIntervention_V1 | undefined
>("customAnimalIntervention", undefined);

export const customAnimalInterventionAtom = atom<
  AnimalIntervention | AnimalIntervention_V1 | undefined,
  [AnimalIntervention | undefined],
  void
>(
  (get: Getter) =>
    updateAnimalInterventionToLatestVersion(
      get(storedCustomAnimalInterventionAtom),
    ),
  (_get: Getter, set: Setter, newValue: AnimalIntervention | undefined) =>
    set(storedCustomAnimalInterventionAtom, newValue),
);

export const baseXRiskInterventionAtom = atomWithStoredHash<
  XRiskIntervention | undefined
>("baseXRiskIntervention", undefined);

export const customXRiskInterventionAtom = atomWithStoredHash<
  XRiskIntervention | undefined
>("customXRiskIntervention", undefined);

export const interventionSpeciesAtom = atom<Animal | undefined>(
  (get: Getter) => {
    const intervention =
      get(customAnimalInterventionAtom) ?? get(baseAnimalInterventionAtom);
    return intervention?.animal;
  },
);

export const [availableInterventionsAtom] = atomsWithQuery(
  (_get) => ({
    queryKey: ["availableInterventions"],
    queryFn: async () => {
      return await DefaultService.getInterventions();
    },
  }),
  getQueryClient,
);

availableInterventionsAtom.debugLabel = "availableInterventions";

export async function assessIntervention(
  intervention:
    | GhdIntervention
    | AnimalIntervention
    | XRiskIntervention
    | undefined,
  parameters: Parameters,
) {
  if (!intervention?.name) {
    return Promise.resolve(null);
  }
  const interventionSamples = await DefaultService.estimateInterventionDalys(
    intervention.name,
    {
      intervention: intervention,
      parameters: parameters,
    },
  );
  return interventionSamples;
}

export const [ghdInterventionAssessmentAtom] = atomsWithQuery((get: Getter) => {
  return {
    queryKey: [
      "ghdInterventionAssessment",
      get(customGhdInterventionAtom) ?? get(baseGhdInterventionAtom),
      get(paramsAtom),
    ],
    queryFn: async () => {
      return await assessIntervention(
        get(customGhdInterventionAtom) ?? get(baseGhdInterventionAtom),
        get(paramsAtom),
      );
    },
  };
}, getQueryClient);

ghdInterventionAssessmentAtom.debugLabel = "ghdInterventionAssessment";

export const [animalInterventionAssessmentAtom] = atomsWithQuery(
  (get: Getter) => {
    return {
      queryKey: [
        "animalInterventionAssessment",
        get(customAnimalInterventionAtom) ?? get(baseAnimalInterventionAtom),
        get(paramsAtom),
      ],
      queryFn: async () => {
        return await assessIntervention(
          get(customAnimalInterventionAtom) ?? get(baseAnimalInterventionAtom),
          get(paramsAtom),
        );
      },
    };
  },
  getQueryClient,
);

animalInterventionAssessmentAtom.debugLabel = "animalInterventionAssessment";

export const [xRiskInterventionAssessmentAtom] = atomsWithQuery(
  (get: Getter) => {
    return {
      queryKey: [
        "xRiskInterventionAssessment",
        get(customXRiskInterventionAtom) ?? get(baseXRiskInterventionAtom),
        get(paramsAtom),
      ],
      queryFn: async () => {
        return await assessIntervention(
          get(customXRiskInterventionAtom) ?? get(baseXRiskInterventionAtom),
          get(paramsAtom),
        );
      },
    };
  },
  getQueryClient,
);

xRiskInterventionAssessmentAtom.debugLabel = "xRiskInterventionAssessment";
