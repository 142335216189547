import { Route, redirect } from "@tanstack/react-router";
import { rootRoute } from "../root";

export const projectsGHDRoute = new Route({
  getParentRoute: () => rootRoute,
  path: "/projects/ghd",
  beforeLoad: () => {
    throw redirect({ to: "/" });
  },
});
