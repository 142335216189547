import { Button } from "@radix-ui/themes";
import { ParamType } from "./configurableParam.interface";

export function SaveAndResetButtons<Value extends ParamType>({
  baseValue,
  paramValue,
  inputValue,
  setInputValue,
  setValue,
  isModified,
}: {
  baseValue: Value;
  paramValue: Value;
  inputValue: Value;
  setInputValue: (value: Value) => void;
  setValue: (value: Value | undefined) => void;
  isModified: boolean;
}) {
  const isInputChanged = inputValue !== paramValue;

  return (
    <div className="mt-8 flex justify-center space-x-2">
      <Button
        onClick={() => {
          if (inputValue === baseValue) {
            setValue(undefined);
          } else {
            setValue(inputValue);
          }
        }}
        className="cursor-pointer"
        variant="soft"
        disabled={!isInputChanged}
      >
        Save changes
      </Button>
      <Button
        onClick={() => {
          setInputValue(baseValue);
          setValue(undefined);
        }}
        className="cursor-pointer"
        variant="soft"
        color="crimson"
        disabled={!isModified}
      >
        Reset
      </Button>
    </div>
  );
}
