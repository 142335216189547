import { Route, redirect } from "@tanstack/react-router";
import { dashboardRoute } from "../dashboard";

export const interventionsRoute = new Route({
  getParentRoute: () => dashboardRoute,
  path: "/interventions",
  beforeLoad: () => {
    throw redirect({ to: "/" });
  },
});
