import * as TabsPrimitive from "@radix-ui/react-tabs";
import { useAtom, useAtomValue, useSetAtom } from "jotai";

import { atomWithStoredHash } from "../../utils/state";
import { availableInterventionsAtom } from "../../stores/interventions";
import {
  availableProjectsPerGroupAtom,
  baseProjectIdAtom,
  customAttributesAtom,
  customSourceInterventionAtom,
} from "../../stores/projects";
import {
  baseInterventionAtom,
  customInterventionAtom,
} from "../../stores/report";

import {
  formatAreaOrGroup,
  groupInterventionsToObject,
} from "../../utils/interventionAreas";
import { Intervention } from "../../utils/interventions";

import { ProjectsPerGroup } from "../../client";
import { InterventionBody } from "./interventions/Intervention";
import { ProjectBody } from "./projects/Project";
import { TagGroup } from "../../components/TagGroup";

const Tab = TabsPrimitive.Root;
const TabList = TabsPrimitive.List;
const TabTrigger = TabsPrimitive.Trigger;
const TabContent = TabsPrimitive.Content;

const INTERVENTION_TAB: "intervention" = "intervention" as const;
const RESEARCH_TAB: "research" = "research" as const;
type Tab = typeof INTERVENTION_TAB | typeof RESEARCH_TAB;

// Dashboard
//
const selectedDashboardTabAtom = atomWithStoredHash<Tab>(
  "selectedDashboardTab",
  "intervention",
);

export const DashboardContent = () => {
  // Fetch interventions and group them for the tags
  const availableInterventions = useAtomValue(availableInterventionsAtom);
  const interventionsByArea: Record<string, Intervention[]> =
    groupInterventionsToObject(availableInterventions);

  // Fetch research projects (no grouping is needed)
  const availableProjectsByGroup = useAtomValue(availableProjectsPerGroupAtom);

  // Currently selected intervention
  const [baseIntervention, setBaseIntervention] = useAtom(baseInterventionAtom);
  const setCustomIntervention = useSetAtom(customInterventionAtom);

  // Currently selected research project
  const [baseProjectId, setBaseProjectId] = useAtom(baseProjectIdAtom);
  const setSourceIntervention = useSetAtom(customSourceInterventionAtom);
  const setTargetIntervention = useSetAtom(customInterventionAtom);
  const setCustomAttributes = useSetAtom(customAttributesAtom);

  const [selectedTab, setSelectedTab] = useAtom(selectedDashboardTabAtom);
  const setSelectedTabGeneric = (tab: Tab) => {
    if (![INTERVENTION_TAB, RESEARCH_TAB].includes(tab)) {
      throw new Error(`Invalid tab: ${tab}`);
    }
    setSelectedTab(tab);
  };
  // Current tag (depending on the selected tab)
  const selectedTag = (
    selectedTab === "intervention" ? baseIntervention?.name : baseProjectId
  ) as string | null;

  const handleTagClick = (tagId: string) => {
    if (selectedTab === "intervention") {
      // Find the intervention with the given name
      const newIntervention = availableInterventions.find(
        (intervention) => intervention.name === tagId,
      );
      // Set the base and custom interventions to the new intervention
      setBaseIntervention(newIntervention);
      setCustomIntervention(newIntervention);
    } else {
      setBaseProjectId(tagId);
      // Reset customized interventions and attributes for the project
      setSourceIntervention(undefined);
      setTargetIntervention(undefined);
      setCustomAttributes({});
    }
  };
  return (
    <Tab
      defaultValue="intervention"
      className="flex flex-col"
      onValueChange={(s: string) => setSelectedTabGeneric(s as Tab)}
      value={selectedTab}
    >
      <TabList className="mb-4 font-semibold md:text-xl">
        <TabTrigger
          value="intervention"
          className={`px-6 py-3 cursor-pointer focus:outline-none ${
            selectedTab === "intervention"
              ? "border-b-2 dark:border-white "
              : "border-b-2 border-transparent"
          } dark:text-white`}
        >
          Direct Intervention
        </TabTrigger>
        <TabTrigger
          value="research"
          className={`px-6 py-3 focus:outline-none ${
            selectedTab === "research"
              ? "border-b-2 dark:border-white"
              : "border-b-2 border-transparent"
          } dark:text-white`}
        >
          Research Project
        </TabTrigger>
      </TabList>
      <TabContent value="intervention" className="p-4">
        <div className="grid grid-cols-1 gap-4 2xl:grid-cols-4 lg:grid-cols-3 sm:grid-cols-2">
          {Object.entries(interventionsByArea).map(([area, interventions]) =>
            [
              "Global Health and Development",
              "Animal Welfare",
              "Existential Risk",
            ].includes(area) ? (
              <TagGroup
                key={`${area}-interventions`}
                title={area}
                tags={interventions.map((intervention) => ({
                  name: intervention.name,
                  id: intervention.name,
                }))}
                selectedTagId={selectedTag}
                onTagClick={handleTagClick}
                inTwoLines={false}
              />
            ) : undefined,
          )}
        </div>
        {selectedTag !== undefined && <InterventionBody />}
      </TabContent>
      <TabContent value="research" className="p-4">
        <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 sm:grid-cols-2">
          {Object.entries(availableProjectsByGroup).map(([area, projects]) => (
            <TagGroup
              key={`${area}-projects`}
              title={formatAreaOrGroup(area as keyof ProjectsPerGroup)}
              tags={projects.map((project) => ({
                name: project.name,
                id: project.id,
              }))}
              selectedTagId={selectedTag}
              onTagClick={handleTagClick}
              inTwoLines={true}
            />
          ))}
        </div>
        {selectedTag !== undefined && <ProjectBody />}
      </TabContent>
    </Tab>
  );
};
