import { ConfigurableGhdParam } from "../../../../components/configurableParam";

export const InterventionConfiguration = () => {
  return (
    <>
      <p>
        For interventions in global health and development we don&apos;t model
        impact internally, but instead stipulate the range of possible values
        conditional on success.
      </p>
      <p>
        The intervention has a{" "}
        <ConfigurableGhdParam name="prob_no_effect" type="percent" /> of being
        unsuccessful. If it is unsuccessful it will have no effect at all. If it
        is successful, the changes it brings about will have a positive effect
        on overall wellbeing with a probability of{" "}
        <ConfigurableGhdParam name="prob_good" type="percent" />. Assuming the
        outcome is positive, it will cost{" "}
        <ConfigurableGhdParam name="cost_per_daly" type="currency" /> per DALY
        averted. The expected magnitude of negative effects, should the changes
        turn out to be net detrimental, will be{" "}
        <ConfigurableGhdParam name="intensity_bad" type="percent" /> as bad as
        the expected value of its expected positive effects.
      </p>
    </>
  );
};
