import { useDarkModeBg } from "../utils/darkMode";
import { Header } from "./Header";
import { Endnotes } from "../components/Endnotes";

export const DashboardLayout = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  // Sets the body bg color if needed
  useDarkModeBg();

  return (
    <div className="bg-dashboard-light px-3 pb-6 pt-10 text-gray-900 dark:bg-dashboard-dark md:px-20 dark:text-gray-200">
      <Header />
      <main>
        {children}
        <Endnotes />
      </main>
    </div>
  );
};
