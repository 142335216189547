import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { paramAttributesAtom } from "../../stores/params";
import { FormatType, getFormat } from "../../utils/formatting";
import { ConfigurableParamLayout } from "./ConfigurableParamLayout";
import { ParamType } from "./configurableParam.interface";
import NumberInput from "../../components/configurableDistribution/distributionPicker/NumberInput";
/* Note: This function behaves somewhat unusually to work with moral weight params.
   Namely, instead of treating `customValue == undefined` as the default, it treats
   `customValue == baseValue` as the default, and it requires `customValue` to be
   defined or else it raises validation errors.
 */
export function ConfigurableNumericParam({
  customValue,
  baseValue,
  setValue,
  name,
  type = "decimal",
  unit,
}: {
  customValue: number | undefined;
  baseValue: number;
  setValue: (value: ParamType) => void;
  name: string;
  type?: FormatType;
  unit?: string;
}) {
  const paramAttributes = useAtomValue(paramAttributesAtom);
  const metadata = paramAttributes[name];
  const description = metadata?.description ?? "";
  const paramValue = customValue ?? baseValue;
  const [inputValue, setInputValue] = useState<number>(paramValue);
  const formatter = getFormat(type, unit);

  // Ensure that `inputValue` updates whenever `paramValue` changes
  useEffect(() => setInputValue(paramValue), [paramValue]);

  return (
    <ConfigurableParamLayout
      baseValue={baseValue}
      customValue={customValue}
      displayText={formatter.format(paramValue)}
      name={name}
      setValue={setValue}
    >
      <div className="flex items-center">
        <NumberInput
          id={name}
          label={name}
          value={inputValue ?? paramValue}
          setValue={setInputValue}
          type={type}
        />
      </div>
      <label htmlFor={name}>{description}</label>
    </ConfigurableParamLayout>
  );
}
