export const riskEraSets = [
  {
    name: "Low Risk",
    eras: [
      {
        length: 30,
        annual_extinction_risk: 0.001,
        absolute_risks_by_type: {
          "ai misalignment": 0.0001845,
          "ai misuse": 0.0001845,
          bio: 0.000123,
          nano: 0.000123,
          natural: 0.000016,
          nukes: 0.000246,
          unknown: 0.000123,
        },
      },
      {
        length: 200,
        annual_extinction_risk: 0.0001,
        absolute_risks_by_type: {
          "ai misalignment": 0.00001575,
          "ai misuse": 0.00001575,
          bio: 0.0000105,
          nano: 0.0000105,
          natural: 0.0000016,
          nukes: 0.000021,
          unknown: 0.0000105,
        },
      },
      {
        length: 1000,
        annual_extinction_risk: 0.00001,
        absolute_risks_by_type: {
          "ai misalignment": 0.0000018,
          "ai misuse": 0,
          bio: 0,
          nano: 0,
          natural: 0,
          nukes: 0,
          unknown: 0.0000082,
        },
      },
      {
        length: 100000000,
        annual_extinction_risk: 0.000001,
        absolute_risks_by_type: {
          "ai misalignment": 0,
          "ai misuse": 0,
          bio: 0,
          nano: 0,
          natural: 0,
          nukes: 0,
          unknown: 0.0000001,
        },
      },
    ],
  },
  {
    name: "High Risk",
    eras: [
      {
        length: 30,
        annual_extinction_risk: 0.02,
        absolute_risks_by_type: {
          "ai misalignment": 0.0111,
          "ai misuse": 0.00222,
          bio: 0.00222,
          nano: 0.00222,
          natural: 0.000016,
          nukes: 0.00111,
          unknown: 0.00111,
        },
      },
      {
        length: 100,
        annual_extinction_risk: 0.01016,
        absolute_risks_by_type: {
          "ai misalignment": 0.005,
          "ai misuse": 0.001,
          bio: 0.001,
          nano: 0.001,
          natural: 0.000016,
          nukes: 0.001,
          unknown: 0.001,
        },
      },
      {
        length: 200,
        annual_extinction_risk: 0.005,
        absolute_risks_by_type: {
          "ai misalignment": 0.00277,
          "ai misuse": 0.00055,
          bio: 0.00055,
          nano: 0.00055,
          natural: 0.0000017,
          nukes: 0.000277,
          unknown: 0.00055,
        },
      },
      {
        length: 1000,
        annual_extinction_risk: 0.0001,
        absolute_risks_by_type: {
          "ai misalignment": 0.000018,
          "ai misuse": 0,
          bio: 0,
          nano: 0,
          natural: 0,
          nukes: 0,
          unknown: 0.000082,
        },
      },
      {
        length: 100000000,
        annual_extinction_risk: 0.0000008,
        absolute_risks_by_type: {
          "ai misalignment": 0,
          "ai misuse": 0,
          bio: 0,
          nano: 0,
          natural: 0,
          nukes: 0,
          unknown: 0.0000008,
        },
      },
    ],
  },
  {
    name: "Short Perils",
    eras: [
      {
        length: 30,
        annual_extinction_risk: 0.0066,
        absolute_risks_by_type: {
          "ai misalignment": 0.0055,
          "ai misuse": 0.00061,
          bio: 0.000042,
          nano: 0.00011,
          natural: 0.000016,
          nukes: 0.00023,
          unknown: 0.00012,
        },
      },
      {
        length: 100,
        annual_extinction_risk: 0.0033,
        absolute_risks_by_type: {
          "ai misalignment": 0.0025,
          "ai misuse": 0.0003,
          bio: 0.000021,
          nano: 0.00005,
          natural: 0.000016,
          nukes: 0.00012,
          unknown: 0.00012,
        },
      },
      {
        length: 200,
        annual_extinction_risk: 0.000691159420289855,
        absolute_risks_by_type: {
          "ai misalignment": 0.0005765556633793782,
          "ai misuse": 0.00006406174037548646,
          bio: 0.0000043518293853632735,
          nano: 0.000012126207902132471,
          natural: 0.0000017200046942272972,
          nukes: 0.000024496197833091343,
          unknown: 0.000013186031173506463,
        },
      },
      {
        length: 1000,
        annual_extinction_risk: 0.00008,
        absolute_risks_by_type: {
          "ai misalignment": 0.000016,
          "ai misuse": 0.000004,
          bio: 0.000004,
          nano: 0.000008,
          natural: 0,
          nukes: 4.0000000000000003e-7,
          unknown: 0.00004,
        },
        proportional_risks_by_type: {
          "ai misalignment": 0.2,
          "ai misuse": 0.05,
          bio: 0.05,
          nano: 0.1,
          natural: 0,
          nukes: 0.005,
          unknown: 0.595,
        },
      },
      {
        length: 100000000,
        annual_extinction_risk: 0.0000008,
        absolute_risks_by_type: {
          "ai misalignment": 0,
          "ai misuse": 0,
          bio: 0,
          nano: 0,
          natural: 0,
          nukes: 0,
          unknown: 0.0000008,
        },
        proportional_risks_by_type: {
          "ai misalignment": 0,
          "ai misuse": 0,
          bio: 0,
          nano: 0,
          natural: 0,
          nukes: 0,
          unknown: 1,
        },
      },
    ],
  },
  {
    name: "Long Perils",
    eras: [
      {
        length: 30,
        annual_extinction_risk: 0.0066,
        absolute_risks_by_type: {
          "ai misalignment": 0.0055,
          "ai misuse": 0.00061,
          bio: 0.000042,
          nano: 0.00011,
          natural: 0.000016,
          nukes: 0.00023,
          unknown: 0.00012,
        },
      },
      {
        length: 100,
        annual_extinction_risk: 0.003,
        absolute_risks_by_type: {
          "ai misalignment": 0.0025,
          "ai misuse": 0.0003,
          bio: 0.00002,
          nano: 0.00005,
          natural: 0.000016,
          nukes: 0.0001,
          unknown: 0.00006,
        },
      },
      {
        length: 20000,
        annual_extinction_risk: 0.000691159420289855,
        absolute_risks_by_type: {
          "ai misalignment": 0.0005765556633793782,
          "ai misuse": 0.00006406174037548646,
          bio: 0.0000043518293853632735,
          nano: 0.000012126207902132471,
          natural: 0.0000017200046942272972,
          nukes: 0.000024496197833091343,
          unknown: 0.000013186031173506463,
        },
      },
      {
        length: 100000000,
        annual_extinction_risk: 0.0000008,
        absolute_risks_by_type: {
          "ai misalignment": 0,
          "ai misuse": 0,
          bio: 0,
          nano: 0,
          natural: 0,
          nukes: 0,
          unknown: 0.0000008,
        },
        proportional_risks_by_type: {
          "ai misalignment": 0,
          "ai misuse": 0,
          bio: 0,
          nano: 0,
          natural: 0,
          nukes: 0,
          unknown: 1,
        },
      },
    ],
  },
];
