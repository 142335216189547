import { ParamType } from "./configurableParam.interface";
import { useAtom, useAtomValue } from "jotai";
import { clone, isEqual, omit } from "lodash-es";
import {
  Animal,
  AnimalIntervention,
  AnimalInterventionParams,
  GhdIntervention,
  GhdInterventionParams,
  LongTermParams,
  MoralWeightsParams,
  XRiskIntervention,
} from "../../client";
import {
  baseAnimalInterventionAtom,
  baseGhdInterventionAtom,
  baseXRiskInterventionAtom,
  customAnimalInterventionAtom,
  customGhdInterventionAtom,
  customXRiskInterventionAtom,
  interventionSpeciesAtom,
} from "../../stores/interventions";
import { defaultParamsAtom, paramsAtom } from "../../stores/params";
import { DistributionSpec } from "../../utils/distributions";
import { FormatType } from "../../utils/formatting";
import { ConfigurableDistributionParam } from "./ConfigurableDistributionParam";
import { ConfigurableParam } from "./ConfigurableParam";
import { ConfigurablePathParam } from "./ConfigurablePathParam";

export function GlobalParam({
  path,
  type = "decimal",
  unit,
  literalOptions,
  displayOnTrue = "",
  displayOnFalse = "",
}: {
  path: string;
  type?: FormatType;
  unit?: string;
  displayOnTrue?: string;
  displayOnFalse?: string;
  literalOptions?: string[];
}) {
  return ConfigurablePathParam({
    valueAtom: paramsAtom,
    defaultValueAtom: defaultParamsAtom,
    path: path,
    type: type,
    unit: unit,
    displayOnTrue: displayOnTrue,
    displayOnFalse: displayOnFalse,
    literalOptions: literalOptions,
  });
}

export function ConfigurableGlobalGhdParam({
  name,
  type = "decimal",
  unit,
  displayOnTrue = "",
  displayOnFalse = "",
}: {
  name: keyof GhdInterventionParams;
  type?: FormatType;
  unit?: string;
  displayOnTrue?: string;
  displayOnFalse?: string;
}) {
  return (
    <ConfigurablePathParam
      valueAtom={paramsAtom}
      defaultValueAtom={defaultParamsAtom}
      path={`ghd_intervention_params.${name}`}
      type={type}
      unit={unit}
      displayOnTrue={displayOnTrue}
      displayOnFalse={displayOnFalse}
    />
  );
}

export function ConfigurableGhdParam({
  name,
  type = "decimal",
  unit,
  displayOnTrue = "",
  displayOnFalse = "",
  literalOptions = [],
}: {
  name: keyof GhdIntervention;
  type?: FormatType;
  unit?: string;
  displayOnTrue?: string;
  displayOnFalse?: string;
  literalOptions?: string[];
}) {
  return (
    <ConfigurablePathParam
      valueAtom={customGhdInterventionAtom}
      defaultValueAtom={baseGhdInterventionAtom}
      path={name}
      type={type}
      unit={unit}
      displayOnTrue={displayOnTrue}
      displayOnFalse={displayOnFalse}
      literalOptions={literalOptions}
    />
  );
}

export function ConfigurableAnimalParam({
  name,
  type = "decimal",
  unit,
  displayOnTrue = "",
  displayOnFalse = "",
  literalOptions = [],
}: {
  name: keyof AnimalIntervention;
  type?: FormatType;
  unit?: string;
  displayOnTrue?: string;
  displayOnFalse?: string;
  literalOptions?: string[];
}) {
  return (
    <ConfigurablePathParam
      valueAtom={customAnimalInterventionAtom}
      defaultValueAtom={baseAnimalInterventionAtom}
      path={name}
      type={type}
      unit={unit}
      displayOnTrue={displayOnTrue}
      displayOnFalse={displayOnFalse}
      literalOptions={literalOptions}
    />
  );
}

/*
 * Configuration for a param containing a dict from animal species to
 * DistributionSpec.
 */
export function ConfigurableSpeciesSpecificParam({
  name,
  type = "decimal",
  unit,
}: {
  name: keyof AnimalInterventionParams;
  type?: FormatType;
  unit?: string;
}) {
  const species = useAtomValue(interventionSpeciesAtom);
  if (species == undefined) {
    // User hasn't selected a base intervention yet.
    return <span>unknown, please select an intervention</span>;
  }

  return (
    <ConfigurablePathParam
      valueAtom={paramsAtom}
      defaultValueAtom={defaultParamsAtom}
      path={`animal_intervention_params.${name}`}
      keyName={species}
      type={type}
      unit={unit}
    />
  );
}

/*
 * Special function for moral weights because they're stored in a weird format.
 */

type OverrideType = MoralWeightsParams["override_type"];
export function ConfigurableMoralWeightParam({
  name,
  optionMap,
}: {
  name: keyof MoralWeightsParams;
  optionMap?: Record<string, string>;
}) {
  const defaultParams = useAtomValue(defaultParamsAtom);
  const [allParams, setAllParams] = useAtom(paramsAtom);

  // This always exists, as Parameters are initialized with default values.
  const baseObj =
    defaultParams.animal_intervention_params!.moral_weight_params!;

  const customObj = {
    override_type: baseObj.override_type,
    sentience_ranges: clone(baseObj.sentience_ranges),
    moral_weights_override: clone(baseObj.moral_weights_override),
    welfare_capacities_override: clone(baseObj.welfare_capacities_override),
    ...(allParams.animal_intervention_params?.moral_weight_params ?? {}),
  };

  const species = useAtomValue(interventionSpeciesAtom);
  if (species === undefined) {
    return <span>unknown</span>;
  }

  type Valueof<T> = T[keyof T];
  const accessBySpeciesAsRelevant =
    name === "override_type"
      ? (v: Valueof<MoralWeightsParams>) => v
      : (v: Valueof<MoralWeightsParams>) => v?.[species as keyof typeof v];

  const baseMoralWeight = accessBySpeciesAsRelevant(baseObj[name]);
  const customMoralWeight = accessBySpeciesAsRelevant(customObj[name]);

  const setMoralWeight = (
    value: OverrideType | DistributionSpec | undefined,
  ) => {
    // Note: If `value` is undefined, we set the moral weight to
    //`baseMoralWeight` instead of omitting it because the backend expects the
    // `moral_weights` dict to have populated values for every species. Our
    // standard logic to merge defaultParamsAtom with paramsAtom doesn't work
    // because the `moral_weights` dict is a sub-field on a param, not the param
    // itself.
    if (name === "override_type") {
      customObj.override_type = (value ?? baseMoralWeight) as OverrideType;
    } else {
      const objUnderName = customObj[name]! as Record<Animal, DistributionSpec>;
      objUnderName[species] = (value ?? baseMoralWeight) as DistributionSpec;
    }

    // If the moral weights dict has no custom values, the moral weights object
    // is removed from animal intervention params.
    let newAnimalParams: AnimalInterventionParams | undefined;
    if (
      typeof value !== "undefined" &&
      !isEqual(customObj?.[name], baseObj[name])
    ) {
      newAnimalParams = {
        ...allParams.animal_intervention_params,
        moral_weight_params: customObj,
      };
    } else {
      const newMoralWeightParams = omit(
        allParams?.animal_intervention_params?.moral_weight_params,
        name,
      );
      newAnimalParams = {
        ...allParams.animal_intervention_params,
        moral_weight_params: newMoralWeightParams,
      };
    }

    // If this resulted in an empty animal params object, the animal params
    // object is removed from the params atom.
    if (Object.keys(newAnimalParams).length != 0) {
      setAllParams({
        ...allParams,
        animal_intervention_params: newAnimalParams,
      });
    } else {
      setAllParams(omit(allParams, "animal_intervention_params"));
    }
  };

  if (name === "override_type") {
    return (
      <ConfigurableParam
        customValue={customMoralWeight as ParamType}
        baseValue={baseMoralWeight as string}
        setValue={setMoralWeight as (value: ParamType | undefined) => void}
        optionMap={optionMap}
        name={name}
        literalOptions={optionMap ? Object.keys(optionMap) : []}
      />
    );
  }
  return (
    <ConfigurableDistributionParam
      customDistribution={customMoralWeight as DistributionSpec}
      baseDistribution={baseMoralWeight as DistributionSpec}
      setDistribution={setMoralWeight}
      name="moral_weight_params"
    />
  );
}

export function ConfigurableLongTermParam({
  name,
  type = "decimal",
  unit,
}: {
  name: keyof LongTermParams;
  type?: FormatType;
  unit?: string;
}) {
  return (
    <ConfigurablePathParam
      valueAtom={paramsAtom}
      defaultValueAtom={defaultParamsAtom}
      path={`longterm_params.${name}`}
      type={type}
      unit={unit}
    />
  );
}

export function ConfigurableLongTermRiskTypeParam({
  name,
  type = "decimal",
}: {
  name:
    | "catastrophe_extinction_risk_ratios"
    | "catastrophe_intensities"
    | "fractions_of_near_term_total_risk";
  type?: FormatType;
}) {
  const baseIntervention = useAtomValue(baseXRiskInterventionAtom);
  if (baseIntervention == undefined) {
    return <span>unknown, please select an intervention</span>;
  }
  const riskType = baseIntervention.risk_type;

  return (
    <ConfigurablePathParam
      valueAtom={paramsAtom}
      defaultValueAtom={defaultParamsAtom}
      path={`longterm_params.${name}`}
      keyName={riskType}
      type={type}
    />
  );
}

export function ConfigurableXRiskParam({
  name,
  type = "decimal",
  unit,
  displayOnTrue = "",
  displayOnFalse = "",
  literalOptions = [],
}: {
  name: keyof XRiskIntervention;
  type?: FormatType;
  unit?: string;
  displayOnTrue?: string;
  displayOnFalse?: string;
  literalOptions?: string[];
}) {
  return (
    <ConfigurablePathParam
      valueAtom={customXRiskInterventionAtom}
      defaultValueAtom={baseXRiskInterventionAtom}
      path={name}
      type={type}
      unit={unit}
      displayOnTrue={displayOnTrue}
      displayOnFalse={displayOnFalse}
      literalOptions={literalOptions}
    />
  );
}
