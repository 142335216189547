import { Route, redirect } from "@tanstack/react-router";
import { dashboardRoute } from "../dashboard";

export const projectsRoute = new Route({
  getParentRoute: () => dashboardRoute,
  path: "/projects",
  beforeLoad: () => {
    throw redirect({ to: "/" });
  },
});
