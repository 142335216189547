import { Parameters, LongTermParams, AnimalIntervention } from "../../client";
import {
  Parameters as Parameters_V1,
  LongTermParams as LongTermParams_V1,
  AnimalIntervention as AnimalIntervention_V1,
} from "../../oldModels/V1";
import {
  UniformDistributionSpec,
  ConstantDistributionSpec,
  ConfidenceDistributionSpec,
  GammaDistributionSpec,
  BetaDistributionSpec,
  CategoricalDistributionSpec,
} from "../../client";
import { distributionMean } from "../distributions";

/////////////////
// Params
////////////////

const isLongtermV1 = (data: LongTermParams | LongTermParams_V1) => {
  if (!("version" in data)) {
    return true;
  }
  return false;
};

export const updateParamsToLatestVersion = (
  oldData: Parameters_V1 | Parameters,
): Parameters => {
  const newData = { ...oldData } as Parameters | Parameters_V1;
  try {
    if (
      "longterm_params" in oldData &&
      isLongtermV1(oldData.longterm_params!)
    ) {
      convertFromLongTermV1ToV2(newData as Parameters_V1);
    }
  } catch (e) {
    console.log(e);
  }
  // In case of error, just return old version.
  return newData as Parameters;
};

const applyConversions = (
  obj: object,
  conversionList: ((v: object) => object)[],
) => {
  let newObj = { ...obj };
  for (const conversion of conversionList) {
    newObj = conversion(newObj);
  }
  return newObj;
};

const convertConstantToDistribution = (keyName: string) => {
  return (obj: object) => {
    const definedKeyName = keyName as keyof typeof obj;
    const newObj = {
      ...obj,
      [definedKeyName]: obj[definedKeyName] as
        | number
        | (
            | UniformDistributionSpec
            | ConstantDistributionSpec
            | ConfidenceDistributionSpec
            | GammaDistributionSpec
            | BetaDistributionSpec
            | CategoricalDistributionSpec
          ),
    };
    if (keyName in newObj) {
      if (typeof newObj[definedKeyName] === "number") {
        newObj[definedKeyName] = {
          type: "constant",
          distribution: "constant",
          value: newObj[definedKeyName] as number,
        };
      }
    }
    return newObj;
  };
};

const convertFromLongTermV1ToV2 = (newData: Parameters_V1 | Parameters) => {
  // Convert from constant params
  newData.longterm_params = applyConversions(
    newData.longterm_params as LongTermParams_V1,
    [
      convertConstantToDistribution("stellar_population_capacity"),
      convertConstantToDistribution("galactic_density"),
      convertConstantToDistribution("supercluster_density"),
      convertConstantToDistribution("expansion_speed"),
    ],
  ) as LongTermParams;
  newData.longterm_params.version = "2";
};

/////////////////
// Animal Interventions
////////////////

export const updateAnimalInterventionToLatestVersion = (
  oldData: AnimalIntervention_V1 | AnimalIntervention | undefined,
): AnimalIntervention | undefined => {
  if (!oldData) return oldData;
  const newData = { ...oldData } as AnimalIntervention | AnimalIntervention_V1;
  try {
    // eslint-disable-next-line
    // @ts-ignore
    if (!("version" in oldData) || oldData.version === "1") {
      convertAnimalInterventionV1ToV2(newData as AnimalIntervention_V1);
    }
  } catch (e) {
    console.log(e);
  }
  // In case of error, just return old version.
  return newData as AnimalIntervention;
};

const convertProbSuccessToProbNoEffect = (
  data: Partial<AnimalIntervention_V1>,
) => {
  const { prob_success: probSuccess } = data;
  if (probSuccess) {
    // eslint-disable-next-line
    // @ts-ignore
    data.prob_no_effect = 1 - distributionMean(probSuccess);
    // eslint-disable-next-line
    // @ts-ignore
    data.intensity_bad = 0;
    // eslint-disable-next-line
    // @ts-ignore
    data.prob_good = 1;
    delete data.prob_success;
  }
  return data as AnimalIntervention;
};

const convertAnimalInterventionV1ToV2 = (
  newData: AnimalIntervention_V1 | AnimalIntervention,
) => {
  convertProbSuccessToProbNoEffect(newData);
  // eslint-disable-next-line
  // @ts-ignore
  newData.version = "2";
};
