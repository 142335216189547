import * as BaseDialog from "@radix-ui/react-dialog";
import { ParamType } from "./configurableParam.interface";
import { Dialog } from "@radix-ui/themes";
import { SaveAndResetButtons } from "./SaveAndResetButtons";
import { useAtomValue } from "jotai";
import { useEffect, useState } from "react";
import { paramAttributesAtom } from "../../stores/params";
import Markdown from "../../components/Markdown";
import { InlineButton } from "../../components/elements/InlineButton";

export function ConfigurableLiteralParam({
  customValue,
  baseValue,
  setValue,
  name,
  optionMap,
  literalOptions,
}: {
  customValue: string | undefined;
  baseValue: string;
  optionMap?: Record<string, string>;
  setValue: (value: ParamType | undefined) => void;
  name: string;
  literalOptions: string[];
}) {
  const isModified = customValue !== undefined && customValue != baseValue;
  const paramAttributes = useAtomValue(paramAttributesAtom);
  const metadata = paramAttributes[name];
  const paramValue = customValue ?? baseValue;
  const [inputValue, setInputValue] = useState<string>(paramValue);

  // Ensure that `inputValue` updates whenever `paramValue` changes
  useEffect(() => setInputValue(paramValue), [paramValue]);

  const dropdownID = `dropdown-${name.replace(" ", "-").toLowerCase()}}`;

  return (
    <Dialog.Root>
      {/* We use the primitive trigger component so as to use asChild */}
      <BaseDialog.Trigger asChild>
        <InlineButton isHighlighted={isModified}>
          {optionMap ? optionMap[paramValue] ?? paramValue : paramValue}
        </InlineButton>
      </BaseDialog.Trigger>
      <Dialog.Content>
        <Dialog.Title>
          Modifying attribute: {metadata?.title ?? name}
        </Dialog.Title>
        <div>
          <p>This is the currently selected option:</p>
          <select
            id={dropdownID}
            name={name}
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
            value={inputValue}
            className="mb-4 block w-full rounded select select-bordered"
          >
            {literalOptions.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
          <label htmlFor={dropdownID}>
            <Markdown>{metadata.description}</Markdown>
          </label>
        </div>
        {SaveAndResetButtons({
          baseValue: baseValue,
          paramValue: paramValue,
          inputValue: inputValue,
          setInputValue: setInputValue,
          setValue: setValue,
          isModified: isModified,
        })}
      </Dialog.Content>
    </Dialog.Root>
  );
}
