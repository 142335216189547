import { ParamType } from "./configurableParam.interface";
import { DistributionSpec } from "../../utils/distributions";
import { FormatType } from "../../utils/formatting";
import { ConfigurableDistributionParam } from "./ConfigurableDistributionParam";
import { ConfigurableBooleanParam } from "./ConfigurableBooleanParam";
import { ConfigurableNumericParam } from "./ConfigurableNumericParam";
import { ConfigurableLiteralParam } from "./ConfigurableLiteralParam";

export {
  ConfigurableDistributionParam,
  ConfigurableBooleanParam,
  ConfigurableLiteralParam,
  ConfigurableNumericParam,
};

export function ConfigurableParam({
  customValue,
  baseValue,
  setValue,
  name,
  type = "decimal",
  unit,
  displayOnTrue,
  displayOnFalse,
  literalOptions = [],
  optionMap,
}: {
  customValue: ParamType | undefined;
  baseValue: ParamType;
  setValue: (value: ParamType | undefined) => void;
  optionMap?: Record<string, string>;
  name: string;
  type?: FormatType;
  unit?: string;
  displayOnTrue?: string;
  displayOnFalse?: string;
  literalOptions?: string[];
}) {
  if (baseValue === undefined) {
    throw new Error(
      "The base value must be defined. Perhaps you used the wrong path to the param?",
    );
  }
  if (typeof baseValue == "boolean") {
    return (
      <ConfigurableBooleanParam
        customValue={customValue as boolean | undefined}
        baseValue={baseValue}
        setValue={setValue}
        name={name}
        displayOnTrue={displayOnTrue!}
        displayOnFalse={displayOnFalse!}
      />
    );
  } else if (typeof baseValue == "string") {
    return (
      <ConfigurableLiteralParam
        customValue={customValue as string | undefined}
        baseValue={baseValue}
        setValue={setValue}
        optionMap={optionMap}
        name={name}
        literalOptions={literalOptions}
      />
    );
  } else if (typeof baseValue == "number") {
    return (
      <ConfigurableNumericParam
        customValue={customValue as number | undefined}
        baseValue={baseValue}
        setValue={setValue}
        name={name}
        type={type}
        unit={unit}
      />
    );
  } else {
    return (
      <ConfigurableDistributionParam
        customDistribution={customValue as DistributionSpec | undefined}
        baseDistribution={baseValue}
        setDistribution={setValue}
        name={name}
        type={type}
        unit={unit}
      />
    );
  }
}
