import { ParamType } from "./configurableParam.interface";
import { produce, setAutoFreeze } from "immer";
import { Atom, WritableAtom, useAtom, useAtomValue } from "jotai";
import { get, isEqual, set } from "lodash-es";
import { FormatType } from "../../utils/formatting";
import { ConfigurableParam } from "./ConfigurableParam";
/* Generic function to configure a named parameter on an object. Takes in a
`valueAtom` object holding the custom value, a constant `defaultValueAtom`
object holding the default value, and a `path` to the parameter on the object,
of the form "field_name.subfield_name".

 In some cases, we want to set a sub-field *within* a parameter where the
 parameter has type Record<string, ParamType>. Use the `keyName` parameter to
 specify the name of the sub-field under the parameter. If a sub-field is
 modified, the parameter record will copy its values from the default value,
 only modifying the one sub-field. If the sub-field is set to the default value
 and all other sub-fields equal their default values, the parameter will be
 removed from the params object. */
export function ConfigurablePathParam<
  O extends Record<string, unknown> | (Record<string, unknown> | undefined),
>({
  valueAtom,
  defaultValueAtom,
  path,
  type = "decimal",
  unit,
  keyName,
  displayOnTrue = "",
  displayOnFalse = "",
  literalOptions = [],
}: {
  valueAtom: WritableAtom<O | Promise<O> | undefined, O[], unknown>;
  defaultValueAtom: Atom<O | Promise<O> | undefined>;
  path: string;
  type?: FormatType;
  unit?: string;
  keyName?: string;
  displayOnTrue?: string;
  displayOnFalse?: string;
  literalOptions?: string[];
}) {
  let baseValue: ParamType;
  let customValue: ParamType | undefined;
  let setCustomValue: (value: ParamType | undefined) => void;

  // Default
  const defaultParams = useAtomValue(defaultValueAtom);

  // Custom
  const [customParams, setCustomParams] = useAtom(valueAtom);
  if (keyName == undefined) {
    baseValue = get(defaultParams, path) as ParamType;
    customValue = get(customParams, path) as ParamType | undefined;
    setCustomValue = (value) => {
      setAutoFreeze(false);
      setCustomParams(
        produce(customParams ?? ({} as Exclude<O, undefined>), (draft) => {
          set(draft, path, value);
        }),
      );
    };
  } else {
    const baseRecord = get(defaultParams, path) as Record<string, ParamType>;
    const customRecord = get(customParams, path) as
      | Record<string, ParamType>
      | undefined;
    baseValue = baseRecord[keyName]!;
    customValue = customRecord?.[keyName];
    setCustomValue = (value) => {
      setAutoFreeze(false);
      let newRecord = {
        ...(customRecord ?? baseRecord),
        [keyName]: value ?? baseValue,
      } as Record<string, ParamType> | undefined;
      if (isEqual(newRecord, baseRecord)) {
        newRecord = undefined;
      }
      setCustomParams(
        produce(customParams ?? ({} as Exclude<O, undefined>), (draft) => {
          set(draft, path, newRecord);
        }),
      );
    };
  }

  // extract name from path
  const name = path.split(".")?.slice(-1)[0];

  return ConfigurableParam({
    baseValue: baseValue,
    customValue: customValue,
    setValue: setCustomValue,
    name: name,
    type: type,
    unit: unit,
    displayOnTrue: displayOnTrue,
    displayOnFalse: displayOnFalse,
    literalOptions: literalOptions,
  });
}
