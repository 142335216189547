import { ExternalLinkIcon } from "@radix-ui/react-icons";

export const Header = () => (
  <header className="mb-8 max-w-2xl">
    <h1 className="mb-4 text-6xl font-bold">
      Cross-Cause Cost-Effectiveness Model
    </h1>
    <p className="mb-2">
      The CCM is a tool by Rethink Priorities that helps assess the
      cost-effectiveness of interventions and research projects by interacting
      with ready-made mathematical models with flexible assumptions.{" "}
      <a
        href="https://forum.effectivealtruism.org/posts/pniDWyjc9vY5sjGre/rethink-priorities-cross-cause-cost-effectiveness-model"
        className="font-semibold text-current-color underline"
        target="_blank"
        rel="noreferrer"
      >
        Read more
        <ExternalLinkIcon className="mb-[-2px]" />
      </a>
    </p>
  </header>
);
