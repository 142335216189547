import { useAtomValue } from "jotai";
import React, { Suspense } from "react";
import { match } from "ts-pattern";
import { interventionAtom } from "../../../stores/report";
import { LoadingFallback } from "../../../components/support/LoadingFallback";
import { InterventionConfiguration as AnimalInterventionConfiguration } from "./animalWelfare/InterventionConfiguration";
import { InterventionConfiguration as GhdInterventionConfiguration } from "./ghd/InterventionConfiguration";
import { InterventionConfiguration as XRiskInterventionConfiguration } from "./xRisk/InterventionConfiguration";

export function InterventionConfiguration() {
  const intervention = useAtomValue(interventionAtom);
  if (!intervention) return null;

  return (
    <Suspense fallback={<LoadingFallback />}>
      {match(intervention.area)
        .returnType<React.ReactNode>()
        .with("ghd", () => <GhdInterventionConfiguration />)
        .with("animal-welfare", () => <AnimalInterventionConfiguration />)
        .with("xrisk", () => <XRiskInterventionConfiguration />)
        .otherwise(() => (
          <div></div>
        ))}
    </Suspense>
  );
}
