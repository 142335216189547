import { atom, Getter, Setter } from "jotai";
import { paramsAtom } from "./params";
import { atomsWithQuery } from "jotai-tanstack-query";
import { atomWithStoredHash } from "../utils/state";

import { ProjectGroupLiteral } from "../utils/projectGroups";
import { getQueryClient } from "../utils/query-client";
import {
  DefaultService,
  ResearchProjectAttributesModel,
  ResearchProjectModel,
  ResultIntervention,
} from "../client";
import { DistributionSpec } from "../utils/distributions";

type InterventionID = string;

export class ProjectNotFoundError extends Error {
  id: string;

  constructor(id: string) {
    super(`Project ${id} not found`);
    this.id = id;
  }
}

/*
 * Gets list of projects
 */

// Set currently selected project group
export const projectGroupAtom = atom<ProjectGroupLiteral>("ghd");

// Gets all projects for currently-chosen group
export const [availableProjectsAtom] = atomsWithQuery(
  (get: Getter) => ({
    queryKey: ["availableProjects", get(projectGroupAtom)],
    queryFn: async ({ queryKey: [, group] }) => {
      return await DefaultService.getProjectsByGroup(
        group as ProjectGroupLiteral,
      );
    },
  }),
  getQueryClient,
);

availableProjectsAtom.debugLabel = "availableProjects";

// Gets lists of projects organized by groups
export const [availableProjectsPerGroupAtom] = atomsWithQuery(
  (_get: Getter) => ({
    queryKey: ["availableProjectsPerGroup"],
    queryFn: async () => {
      return await DefaultService.getProjects();
    },
  }),
  getQueryClient,
);

availableProjectsPerGroupAtom.debugLabel = "availableProjectsPerGroup";

/*
 * Returns schema of parameters for projects
 */

export const [availableAttributesAtom] = atomsWithQuery(
  (_get) => ({
    queryKey: ["availableAttributes"],
    queryFn: async () => {
      return await DefaultService.getProjectAttributes();
    },
  }),
  getQueryClient,
);

availableAttributesAtom.debugLabel = "availableAttributes";

/*
 * Base project
 * Set by an id, then retrieved from list of all projects
 */

// Store id to retrieve project form list
export const baseProjectIdAtom = atomWithStoredHash<string | undefined>(
  "baseProjectId",
  undefined,
  {
    boundToPage: true,
  },
);

export const baseProjectAtom = atom(async (get: Getter) => {
  const selectedId = get(baseProjectIdAtom);
  const projects = await Promise.resolve(get(availableProjectsAtom));
  if (selectedId) {
    const selectedProject = projects.find((p) => p.id === selectedId);
    if (!selectedProject) {
      throw new ProjectNotFoundError(selectedId);
    }
    return selectedProject;
  }
});

/*
 * Custom Project
 */

// Intervention this project would cause money to be moved from.
export const customSourceInterventionAtom = atomWithStoredHash<
  InterventionID | ResultIntervention | undefined
>("sourceIntervention", undefined, { boundToPage: true });

// Intervention from which the funding to research this project would come.
export const customResearchFundingCounterfactualInterventionAtom =
  atomWithStoredHash<InterventionID | ResultIntervention | undefined>(
    "researchFundingCounterfactualIntervention",
    undefined,
    { boundToPage: true },
  );

// Intervention this project would cause money to be moved to.
export const customTargetInterventionAtom = atomWithStoredHash<
  InterventionID | ResultIntervention | undefined
>("targetIntervention", undefined, { boundToPage: true });

// Attribute overrides for the project
export const customAttributesAtom = atomWithStoredHash<
  Partial<ResearchProjectAttributesModel>
>("customAttributes", {}, { boundToPage: true });

// Setter to set cusomtized parameters
// Does not include custom targets
export const setCustomAttributeAtom = atom(
  null,
  (
    get: Getter,
    set: Setter,
    name: string,
    value: DistributionSpec | undefined,
  ) => {
    const customAttributes = get(customAttributesAtom);
    if (value !== undefined) {
      // Set the attribute
      set(customAttributesAtom, {
        ...customAttributes,
        [name]: value,
      });
    } else {
      // Remove the attribute
      const { [name as keyof ResearchProjectAttributesModel]: _, ...rest } =
        customAttributes;
      set(customAttributesAtom, rest);
    }
  },
);

// This gives you all the parameters, including the modifications
export const combinedAttributesAtom = atom(async (get: Getter) => {
  const baseProject = await get(baseProjectAtom);
  const customAttributes = get(customAttributesAtom);

  if (baseProject) {
    return {
      ...baseProject.attributes,
      ...customAttributes,
    };
  }
  return undefined;
});

// This is the full, modified, project
// Combines customized attributes with customized source / target interventions
export const fullSelectedProjectAtom = atom<
  Promise<ResearchProjectModel | undefined>
>(async (get: Getter) => {
  const baseProject = await get(baseProjectAtom);
  const selectedSourceIntervention = get(customSourceInterventionAtom);
  const selectedTargetIntervention = get(customTargetInterventionAtom);
  const selectedResearchFundingCounterfactualIntervention = get(
    customResearchFundingCounterfactualInterventionAtom,
  );
  const customAttributes = get(customAttributesAtom);
  const combinedAttributes = await get(combinedAttributesAtom);

  if (!baseProject) {
    return undefined;
  }

  if (
    selectedSourceIntervention === undefined &&
    selectedResearchFundingCounterfactualIntervention === undefined &&
    selectedTargetIntervention === undefined &&
    Object.keys(customAttributes).length === 0
  ) {
    return undefined;
  }

  const source = selectedSourceIntervention ?? baseProject.source_intervention;
  const research_funding_counterfactual =
    selectedResearchFundingCounterfactualIntervention ??
    baseProject.research_funding_counterfactual_intervention;
  const target = selectedTargetIntervention ?? baseProject.target_intervention;

  // Override the source and target interventions by extending the ProjectAssessmentModel from the selected project
  return {
    ...baseProject,
    name: `${baseProject?.name} (modified)`,
    source_intervention: source,
    research_funding_counterfactual_intervention:
      research_funding_counterfactual,
    target_intervention: target,
    attributes: combinedAttributes,
  } as ResearchProjectModel;
});

/*
 * Assessement
 */

export const [projectAssessmentAtom] = atomsWithQuery(
  (get: Getter) => ({
    queryKey: [
      "projectAssessment",
      get(baseProjectIdAtom),
      get(paramsAtom),
      get(customSourceInterventionAtom),
      get(customResearchFundingCounterfactualInterventionAtom),
      get(customTargetInterventionAtom),
      get(customAttributesAtom),
      get(fullSelectedProjectAtom),
    ],
    queryFn: async () => {
      const selectedId = get(baseProjectIdAtom);
      const parameters = get(paramsAtom);
      const project = await get(fullSelectedProjectAtom);

      if (selectedId) {
        if (project !== undefined) {
          // Use a custom project
          const res = await DefaultService.assessCustomProjectWithParams({
            project,
            parameters,
          });
          return res;
        }
        // Use a predefined project
        return await DefaultService.assessProjectWithParams(
          selectedId,
          parameters,
        );
      }
      return null;
    },
  }),
  getQueryClient,
);

projectAssessmentAtom.debugLabel = "projectAssessment";
